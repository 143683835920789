import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "conditionType",
    "keywordField",
    "expectedStatusField",
    "keyword",
    "expectedStatusCodes",
    "url"
  ]

  connect() {
    this.toggleFields(); // Initialize field visibility
  }

  toggleFields() {
    const selected = this.conditionTypeTarget.value;

    // Toggle keyword field visibility
    if (selected === 'keyword_found' || selected === 'keyword_not_found') {
      this.keywordFieldTarget.style.display = 'block';
    } else {
      this.keywordFieldTarget.style.display = 'none';
      this.keywordTarget.value = ''; // Clear the keyword field when hidden
      this.clearValidationError(this.keywordTarget); // Clear any previous validation error
    }

    // Toggle expected status codes field visibility
    if (selected === 'http_status_other') {
      this.expectedStatusFieldTarget.style.display = 'block';
    } else {
      this.expectedStatusFieldTarget.style.display = 'none';
      this.expectedStatusCodesTarget.value = ''; // Clear the expected status codes field when hidden
      this.clearValidationError(this.expectedStatusCodesTarget); // Clear any previous validation error
    }
  }

  validateBeforeSubmit(event) {
    const selected = this.conditionTypeTarget.value;
    const keyword = this.keywordTarget.value;
    let url = this.urlTarget.value.trim(); // Trim any extra whitespace
    const expectedStatusCodes = this.expectedStatusCodesTarget.value;

    // Reset previous validation styles
    this.clearValidationError(this.keywordTarget);
    this.clearValidationError(this.expectedStatusCodesTarget);
    this.clearValidationError(this.urlTarget);

    let valid = true;

    // Prepend "https://" if the URL is missing a valid scheme
    if (!/^(https?:\/\/)/i.test(url) && /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(url)) {
      url = "https://" + url;
      this.urlTarget.value = url; // Update the input field with the modified URL
    }

    // Validate the URL after any necessary conversion
    if (!this.isValidURL(url)) {
      this.showValidationError(
        this.urlTarget,
        "Please enter a valid URL starting with http:// or https://"
      );
      this.urlTarget.scrollIntoView({ behavior: "smooth" });
      valid = false;
    }

    // Validate keyword
    if (
      (selected === "keyword_found" || selected === "keyword_not_found") &&
      keyword.trim() === ""
    ) {
      this.showValidationError(this.keywordTarget, "Please enter a keyword.");
      this.keywordFieldTarget.scrollIntoView({ behavior: "smooth" });
      valid = false;
    }

    // Validate expected status codes
    if (
      selected === "http_status_other" &&
      !this.isValidHttpStatusCodes(expectedStatusCodes)
    ) {
      this.showValidationError(
        this.expectedStatusCodesTarget,
        "Please enter valid HTTP status codes (e.g., 200, 404)."
      );
      this.expectedStatusFieldTarget.scrollIntoView({ behavior: "smooth" });
      valid = false;
    }

    if (!valid) {
      event.preventDefault(); // Prevent the form from being submitted
    }

    return valid;
  }

  isValidURL(url) {
    // Regex to validate a URL with an optional http or https scheme and a valid domain structure
    const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}([\/\w .-]*)*\/?$/;
    return pattern.test(url);
  }

  isValidHttpStatusCodes(codes) {
    // Check if the input is a valid comma-separated list of HTTP status codes (3-digit numbers)
    const regex = /^\d{3}(,\s?\d{3})*$/;
    return regex.test(codes.trim());
  }

  showValidationError(inputElement, message) {
    // Add Tailwind CSS classes to highlight the field with an error
    inputElement.classList.add(
      "border",
      "border-red-500",
      "ring-red-500",
      "focus:ring-red-500"
    );

    // Find existing error message within the parent element
    let errorMessage = inputElement.parentNode.querySelector('.error-message');
    if (!errorMessage) {
      errorMessage = document.createElement('p');
      errorMessage.classList.add('mt-2', 'text-sm', 'text-red-600', 'error-message');
      errorMessage.textContent = message;
      inputElement.parentNode.appendChild(errorMessage);
    }
  }

  clearValidationError(inputElement) {
    // Remove the error classes
    inputElement.classList.remove(
      "border",
      "border-red-500",
      "ring-red-500",
      "focus:ring-red-500"
    );

    // Remove the error message
    let errorMessage = inputElement.parentNode.querySelector('.error-message');
    if (errorMessage) {
      errorMessage.remove();
    }
  }
}
