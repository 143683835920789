import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate"];

  connect() {
    this.startDateTarget.addEventListener("change", this.validate.bind(this));
    this.endDateTarget.addEventListener("change", this.validate.bind(this));
  }

  validate() {
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value;

    if (!startDate || !endDate) {
      alert("Both 'From' and 'To' dates are required.");
      return false; // Prevent form submission
    }

    if (new Date(startDate) > new Date(endDate)) {
      alert("The 'From' date must be earlier than or equal to the 'To' date.");
      this.startDateTarget.focus();
      return false;
    }
  }
}
