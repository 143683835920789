import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        start: Number,
        suffix: String
    }

    connect() {
        console.log(this.startValue);
        this.currentSeconds = this.startValue || 0;

        this.updateCounter();

        this.timer = setInterval(() => {
            this.currentSeconds++;
            this.updateCounter();
        }, 1000);
    }

    disconnect() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    updateCounter() {
        if (this.currentSeconds < 60) {
            this.element.textContent = `${this.currentSeconds} seconds${this.suffixValue}`;
        } else if (this.currentSeconds < 120) {
            this.element.textContent = `1 minute${this.suffixValue}`;
        } else {
            const minutes = Math.floor(this.currentSeconds / 60);
            this.element.textContent = `${minutes} minutes${this.suffixValue}`;
        }
    }
}